import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'layouts';
import GridContainer from '../components/Grid/GridContainer.jsx';
import GridItem from '../components/Grid/GridItem.jsx';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Image from '../components/Image';
import Flag from '../components/Flag';
import axios from 'axios';
import { create } from 'apisauce';
import Helmet from 'react-helmet';
import Constants from '../../static/Constants.jsx';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import RoomIcon from '@material-ui/icons/Room';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Snackbar from '../components/Snackbar/Snackbar';
import SDGTooltip from '../components/SDG/SDGTooltip.jsx';
import getHeaderLInks from '../shared/headerLinks.js';
import ChatPopup from '../components/ChatPopup/ChatPopup.jsx';
import { navigate } from 'gatsby';
import { ThreeDots } from 'react-loader-spinner';
import Button from '@material-ui/core/Button';
import ShareIcon from '@material-ui/icons/Share';
import MoneyIcon from '@material-ui/icons/Money';
import MapParallax from '../components/MapView/MapParallax.jsx';
import ProjectStats from '../components/ProjectStats.jsx';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/Add';
import ExpandLessIcon from '@material-ui/icons/Remove';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useStyles } from '../styles/projects';
import { ISICCodes, PROJECT_STOCK_IMAGES } from '../shared/constants';
import SourceChip from '../components/SourceChip.jsx';
import { numFormatter } from '../shared/numberFormatter';
import { findKey } from 'lodash';
import projectDetailStyles from '../styles/projectDetails';
import SocialShare from '../components/SocialShare/SocialShare.jsx';
import MapWrapper from '../components/GoogleMap/MapWrapper.jsx';

const { sharebutton, shareIcon } = projectDetailStyles;

const axiosInstance = axios.create({ baseURL: Constants.apiUrl });
const apiSauceInstance = create({ axiosInstance });
const apiSauceInstanceMapBox = create({
  baseURL: Constants.mapBoxGeoCodingUrl,
});

const ProjectDetails = ({ ...props }) => {
  const { t, i18n, ready } = useTranslation();
  const { pageContext, location } = props;

  const data = pageContext.pageData ? pageContext.pageData : null;

  if (!data) {
    return;
  }

  const programLink = pageContext.programUrl;
  // const facebookLink = 'https://www.facebook.com/share.php?u';
  // const EmURL = 'https://mail.google.com/mail/?view=cm&fs=1&su';
  // const portalApiLink = Constants.apiUrl;
  // const twitterURL = 'http://twitter.com/intent/tweet?url=URL&text';

  const headerLinks = getHeaderLInks('', programLink, data.noLinks);

  const { headerLogo, headerLogoScroll, industrialParks } =
    (data || {}).node || {};

  const logos = pageContext.logos || [];

  const helmetLink = `${Constants.appUrl}/projectDetails`;
  const [projectData, setProjectData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [coordinates, setCoordinates] = useState([22.7578, 5.9806]);
  // let [downloadedText, setDownlaodedText] = useState('');
  const [openSnack, setOpenSnack] = useState(false);
  const [openSnackFav, setOpenSnackFav] = useState(false);
  const [openChatForm, setOpenChatForm] = useState(false);
  const [contactViewed, setContactViewed] = useState(false);
  const [nodataMessage, setnodataMessage] = useState('No Project to Display');
  const [imageIndx, setImageIndx] = useState(0);
  // const [partnerResult, setPartnerResult] = useState(null);
  const [openShareDialog, setOpenShare] = useState(false);

  const styleClasses = useStyles();

  const getIndustrialPark = cityName => {
    let sez = {};
    if (industrialParks.length) {
      (industrialParks || []).map(industrialPark => {
        if (
          (industrialPark.cityName || '').toLowerCase() ===
          (cityName || '.').toLowerCase()
        ) {
          sez = industrialPark;
        }
      });
    }
    return sez;
  };

  // const getPartnerByCountry = countryName => {
  //   let partnerResult = {};
  //   if (partners.length) {
  //     const projectId = parseInt(window.location.search.replace('?id=', ''));
  //     const coffeeProjects = [7462, 7471];
  //     let partner = {};
  //     if (coffeeProjects.indexOf(projectId) > -1) {
  //       partner = (partners || []).find(
  //         partner =>
  //           (
  //             (((partner || {}).childMarkdownRemark || {}).frontmatter || {})
  //               .title || ''
  //           ).toLowerCase() ===
  //           ('coffee federation of ghana' || '.').toLowerCase()
  //       );
  //     } else {
  //       partner = (partners || []).find(
  //         partner =>
  //           (
  //             (((partner || {}).childMarkdownRemark || {}).frontmatter || {})
  //               .countryName || ''
  //           ).toLowerCase() === (countryName || '.').toLowerCase()
  //       );
  //     }
  //     if (partner) {
  //       partnerResult = (partner.childMarkdownRemark || {}).frontmatter || {};
  //       partnerResult.partnersTeam = (partner.partnersTeam || [])[0] || null;
  //     }
  //   }
  //   return partnerResult;
  // };

  const [industrialPark, setIndustrialPark] = useState(null);
  // const [partner, setPartner] = useState(null);

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.location.search.includes('id')
    ) {
      const pageHref = window.location.search;

      // Construct a new object and pass the page href to URLSearchParams
      const searchParams = new URLSearchParams(
        pageHref.substring(pageHref.indexOf('?'))
      );

      if (searchParams.has('view')) {
        const imageNumber = searchParams.get('view');
        setImageIndx(imageNumber || 0);
      }

      setLoading(true);
      const projectId = parseInt(window.location.search.replace('?id=', ''));
      const favProjectIds = JSON.parse(
        localStorage.getItem(
          `${data.node.programCode ? data.node.programCode : ''}Favorites`
        )
      );

      apiSauceInstance
        .get(
          `${Constants.getProjectDetails}/${projectId}${programLink}/${i18n.language}`
        )
        .then(({ data, status }) => {
          if (status !== 400) {
            data.isFavorite = (favProjectIds || []).find(
              projectID => projectID === data.projectID
            );
            setProjectData(data);
            setIndustrialPark(getIndustrialPark(data.projectCityName));
            //setPartner(getPartnerByCountry(data.projectCountryName));
            if (
              ((data || {}).coordinates || [])[0] &&
              ((data || {}).coordinates || [])[1]
            ) {
              setCoordinates((data || {}).coordinates);
            }
          } else if (status == 400 && data.message) {
            setnodataMessage(data.message);
          } else {
            setnodataMessage('No Project to Display');
          }
          setLoading(false);
        })
        .catch(error => {
          if (error) {
            //do something
          }
          setLoading(false);
        });
    }
  }, [i18n.language]);

  useEffect(() => {
    setLoading(true);
    if (
      projectData &&
      !(projectData.coordinates || [])[0] &&
      !(projectData.coordinates || [])[1]
    ) {
      const searchText = `${projectData.projectCityName} ${projectData.projectRegionName} ${projectData.projectCountryName}`;

      const url = `/${encodeURI(searchText)}.json?access_token=${
        Constants.mapBoxApiKey
      }`;
      apiSauceInstanceMapBox
        .get(url)
        .then(mapCoordinates => {
          if (mapCoordinates.data && mapCoordinates.data.features.length > 0) {
            if (
              mapCoordinates.data.features[0].geometry &&
              mapCoordinates.data.features[0].geometry.coordinates
            ) {
              projectData.coordinates = (
                mapCoordinates.data.features[0].geometry.coordinates || []
              ).reverse();
              setCoordinates(projectData.coordinates);
            }
          }
          setLoading(false);
        })
        .catch(error => {
          if (error) {
            //do something
          }
          setLoading(false);
        });
    }
  }, [projectData]);

  const viewContactClick = () => {
    setOpenChatForm(!openChatForm);
    const projectId = parseInt(window.location.search.replace('?id=', ''));

    if (!contactViewed)
      apiSauceInstance
        .post(`${Constants.setProjectContactViewCount}/${projectId}`)
        .then(() => {
          setContactViewed(true);
        })
        .catch(error => {
          if (error) {
            //do something
          }
        });
  };

  // const downloadProjectDocument = name => {
  //   setDownlaodedText('Your Document will get downloaded shortly...');
  //   const projectId = parseInt(window.location.search.replace('?id=', ''));
  //   if (name) {
  //     const url = `${Constants.apiUrl}/${Constants.getProjectDocumentDetails}/${projectId}`;
  //     const params = {
  //       projectId: projectId,
  //     };
  //     // Create form to post the request
  //     const form = document.createElement('form');
  //     form.setAttribute('method', 'post');
  //     form.setAttribute('action', url);

  //     for (const i in params) {
  //       // eslint-disable-next-line no-prototype-builtins
  //       if (params.hasOwnProperty(i)) {
  //         const input = document.createElement('input');
  //         input.type = 'hidden';
  //         input.name = i;
  //         input.value = params[i];
  //         form.appendChild(input);
  //       }
  //     }
  //     // Add form to current page
  //     document.body.appendChild(form);
  //     // Make a post request
  //     form.submit();
  //     // Remove form from current page
  //     document.body.removeChild(form);
  //     setTimeout(() => {
  //       setDownlaodedText('');
  //     }, 2000);
  //   }
  // };

  // const prepareDocument = (source, type) => {
  //   const byteString = window.atob(source);
  //   const arrayBuffer = new ArrayBuffer(byteString.length);
  //   const int8Array = new Uint8Array(arrayBuffer);
  //   for (let i = 0; i < byteString.length; i++) {
  //     int8Array[i] = byteString.charCodeAt(i);
  //   }
  //   return new Blob([int8Array], { type: type });
  // };

  const projectLocation = projectData
    ? `${projectData.projectAddress ? projectData.projectAddress + ',' : ''}
      ${projectData.projectCityName ? projectData.projectCityName + ',' : ''} ${
        projectData.projectRegionName ? projectData.projectRegionName + ',' : ''
      } ${projectData.projectCountryName ? projectData.projectCountryName : ''}`
    : '';

  const projectMarkerLocation = projectData
    ? `${
        projectData.projectCityName ? projectData.projectCityName + ',' : ''
      } ${
        projectData.projectRegionName ? projectData.projectRegionName + ',' : ''
      } ${projectData.projectCountryName ? projectData.projectCountryName : ''}`
    : '';

  const Page = ({ children, id }) => (
    <div id={id} className="bg-white shadow-1 center pa4">
      {children}
    </div>
  );

  Page.propTypes = {
    children: PropTypes.any,
    // singleMode: PropTypes.any,
    id: PropTypes.any,
  };

  const getAppendedCommaValue = value => {
    let output = '';
    if (value) {
      output = value + ',';
    }
    return output;
  };

  const getCompanySales = companySales => {
    if (companySales) {
      const sales =
        companySales.sort((a, b) => b.annualSalesYear - a.annualSalesYear)[0] ||
        {};
      if (sales) {
        return `${numFormatter(sales.salesAmount)} ${sales.currencyCode}`;
      }
    }
  };

  const getImageName = isicCode => {
    if (isicCode) {
      let sector = findKey(ISICCodes, function(v) {
        return v == isicCode?.[0];
      });

      let imageNameArray =
        PROJECT_STOCK_IMAGES[ISICCodes[sector]] ||
        PROJECT_STOCK_IMAGES[ISICCodes.UNKNOWN];

      let imageName = imageNameArray[imageIndx % imageNameArray.length];

      return imageName;
    } else {
      return '';
    }

    // let output = '';
    // if (isicCode) {
    //   if (isicCode.startsWith(ISICCodes.FISHING)) {
    //     output = 'Fishing.jpeg';
    //   } else if (isicCode.startsWith(ISICCodes.MINING)) {
    //     output = 'Mining.jpeg';
    //   } else if (isicCode.startsWith(ISICCodes.ELECTRICITY)) {
    //     output = 'ElectricityGasSteamWater.jpeg';
    //   } else if (isicCode.startsWith(ISICCodes.ENTERTAINMENT)) {
    //     output = 'Entertainment.jpeg';
    //   } else if (isicCode.startsWith(ISICCodes.HEALTH)) {
    //     output = 'HealthAndSocialWork.jpeg';
    //   } else if (isicCode.startsWith(ISICCodes.HOTEL)) {
    //     output = 'HotelsAndRestaurant.jpeg';
    //   } else if (isicCode.startsWith(ISICCodes.REALESTATE)) {
    //     output = 'RealEstate.jpeg';
    //   } else if (isicCode.startsWith(ISICCodes.TRANSPORT)) {
    //     output = 'TransportStorage.jpg';
    //   } else if (isicCode.startsWith(ISICCodes.AGRICULTURE)) {
    //     output = 'AgricultureHunting.jpeg';
    //   } else if (isicCode.startsWith(ISICCodes.MANUFACTURING)) {
    //     output = 'Manufacturing.jpeg';
    //   } else {
    //     output = 'Manufacturing.png';
    //   }
    // }
    // return output;
  };

  let countries_data_en =
    t('countries_data', {
      returnObjects: true,
      lng: 'en',
    }) || [];

  const getCountryData = (countryName, countryID) => {
    if (i18n.language == 'en') {
      return countryName;
    } else {
      let id = countryID;
      if (!ready) {
        return 'Loading...';
      } else {
        let data = countries_data_en.find(({ countryID }) => countryID == id);
        return data?.countryName || countryName;
      }
    }
  };

  return (
    <Page id={'detailsPageID'}>
      <Layout
        programLink={programLink}
        imageName={
          projectData
            ? projectData.projectLogo
              ? projectData.projectLogo
              : 
              //#6196 Code commented for changes regarding No Image 
              //getImageName(
              //   projectData.projectSectorCode
              //     ? projectData.projectSectorCode[0]
              //     : ''
              // )
              'No_Image_Background.jpg'
            : getImageName('')
        }
        customView={
          projectData ? (projectData.projectLogo ? true : false) : false
        }
        headerLinks={headerLinks}
        logos={logos}
        headerLogo={headerLogo}
        headerLogoScroll={headerLogoScroll}
        visitedLinks={[
          { url: '/', key: 'home', name: 'Home' },
          { url: '/projects', key: 'projects', name: 'Projects' },
        ]}
        currentPage={projectData ? projectData.projectOpportunityType : ''}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>Investment Promotion Portal ProjectDetails</title>
          <link rel="canonical" href={helmetLink} />
        </Helmet>

        {projectData && !loading ? (
          // <MapParallax
          //   projects={[
          //     {
          //       coordinates: coordinates,
          //       id: projectData ? projectData.projectID : null,
          //       regionName: projectMarkerLocation,
          //       currentPage: 'Project',
          //     },
          //     {
          //       coordinates: (industrialPark || {}).coordinates,
          //       id: projectData ? projectData.projectID : null,
          //       regionName: (industrialPark || '').location,
          //       currentPage: 'SEZ',
          //     },
          //   ]}
          // />
          
          <MapWrapper 
            projects={[
              {
                coordinates: coordinates,
                id: projectData ? projectData.projectID : null,
                regionName: projectMarkerLocation,
                currentPage: 'Project',
              },
              {
                coordinates: (industrialPark || {}).coordinates,
                id: projectData ? projectData.projectID : null,
                regionName: (industrialPark || '').location,
                currentPage: 'SEZ',
              },
            ]}
          />
        ) : (
          ''
        )}

        <div className="container ">
          {projectData ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={8}>
                <SocialShare
                  openShareDialog={openShareDialog}
                  setOpenShare={setOpenShare}
                  link={
                    location.origin +
                    location.pathname +
                    '?id=' +
                    projectData.projectID +
                    '&view=' +
                    imageIndx
                  }
                  titleLangKey="share_project_via"
                  mailSubject="Invest In ACP Project Details"
                />
                {/* <h4> */}
                {/* {t('Project Summary')}

                  <Tooltip title="Share Project" placement="top-start">
                    <Button
                      css={sharebutton}
                      onClick={() => {
                        setOpenShare(true);
                      }}
                    >
                      <ShareIcon style={shareIcon} />
                    </Button>
                  </Tooltip> */}
                {/* {<PrintButton id={'detailsPageID'} />} */}
                {/* {projectData.isFavorite ? (
                    <Tooltip
                      title="Remove from Favorites"
                      placement="top-start"
                    >
                      <StarOutlinedIcon
                        style={favIcon}
                        onClick={() => {
                          handleFavoriteProjects(projectData.projectID, false);
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Add to Favorites" placement="top-start">
                      <StarBorderOutlinedIcon
                        style={favIcon}
                        onClick={() => {
                          handleFavoriteProjects(projectData.projectID, true);
                        }}
                      />
                    </Tooltip>
                  )} */}
                {/* {mobileScreen && <br />} */}

                {/* <span style={viewsStyle}>
                    {projectData.projectViewCount
                      ? (projectData.projectViewCount || '').toLocaleString() +
                        ' Views'
                      : ''}
                  </span> */}
                {/* </h4> */}

                <div className="project-title">
                  <h3 style={{ marginBottom: '5px' }}>
                    {t('project_title')}
                    <Tooltip title={t('share')} placement="top-start">
                      <Button
                        css={sharebutton}
                        onClick={() => {
                          setOpenShare(true);
                        }}
                      >
                        <ShareIcon style={shareIcon} />
                      </Button>
                    </Tooltip>
                  </h3>
                  <div style={{ marginLeft: '-10px' }}>
                    {projectData.isYouthEmpowerment ? (
                      <span style={{ marginLeft: '10px' }}>
                        <SourceChip sourceName={t('youth')} />
                      </span>
                    ) : null}
                    {projectData.isWomenEmpowerment ? (
                      <span style={{ marginLeft: '10px' }}>
                        <SourceChip sourceName={t('women')} />
                      </span>
                    ) : null}
                  </div>
                  <p>{projectData.projectTitle}</p>
                </div>

                <div className="investment-proposal">
                  <h3>{t('investment_proposal')}</h3>
                  <p>
                    {projectData.projectOpportunitySummary
                      ? projectData.projectOpportunitySummary
                      : 'N/A'}
                  </p>
                </div>

                <div className="project-strengths">
                  <h4>{t('project_strengths')}</h4>
                  <p>
                    {projectData.companyAndProjectStrengths
                      ? projectData.companyAndProjectStrengths
                      : 'N/A'}
                  </p>
                </div>

                <GridContainer spacing={5}>
                  <GridItem md={6} sm={6} xs={12}>
                    <ProjectStats
                      title={t('project_sector')}
                      body={
                        projectData.projectSectorDescription &&
                        projectData.projectSectorDescription.length > 0
                          ? projectData.projectSectorDescription[0]
                          : 'N/A'
                      }
                    ></ProjectStats>
                  </GridItem>
                  <GridItem md={6} sm={6} xs={12}>
                    <ProjectStats
                      title={t('project_campaign')}
                      body={projectData.projectCampaignName}
                    ></ProjectStats>
                  </GridItem>
                  <GridItem md={6} sm={6} xs={12}>
                    <ProjectStats
                      title={t('project_code')}
                      body={projectData.projectCode}
                    ></ProjectStats>
                  </GridItem>
                  <GridItem md={6} sm={6} xs={12}>
                    <ProjectStats
                      title={t('projected_annual_sales')}
                      body={
                        projectData.projectEstimatedAnnualSale
                          ? (numFormatter(
                              projectData.projectEstimatedAnnualSale
                            ) || '') + ' EUR'
                          : '0 EUR'
                      }
                    ></ProjectStats>
                  </GridItem>
                  <GridItem md={6} sm={6} xs={12}>
                    <ProjectStats
                      title={t('products_services_resulting_from_project')}
                      body={
                        projectData &&
                        projectData.projectProductDescription &&
                        projectData.projectProductDescription.length > 0 ? (
                          projectData.projectProductDescription.map(item => (
                            <>{item}</>
                          ))
                        ) : (
                          <p>N/A</p>
                        )
                      }
                    ></ProjectStats>
                  </GridItem>
                  <GridItem md={6} sm={6} xs={12}>
                    <ProjectStats
                      title={t('opportunity_type')}
                      body={
                        projectData ? projectData.projectOpportunityType : ''
                      }
                    ></ProjectStats>
                  </GridItem>
                  <GridItem md={6} sm={6} xs={12}>
                    <ProjectStats
                      title={t('project_country')}
                      body={
                        <GridContainer
                          xs={12}
                          sm={12}
                          md={12}
                          onClick={() => {
                            navigate(
                              programLink +
                                '/' +
                                (
                                  getCountryData(
                                    projectData.projectCountryName,
                                    projectData.projectCountryID || 0
                                  ) || ''
                                ).replace(/ .*/, '')
                            );
                          }}
                          className="country-name"
                        >
                          <div className="country-block-details">
                            <div style={{ paddingRight: '10px' }}>
                              <Flag
                                imageName={
                                  getCountryData(
                                    projectData.projectCountryName,
                                    projectData.projectCountryID || 0
                                  ) + '.png'
                                }
                              />
                            </div>
                            <div style={{ paddingLeft: '10px' }}>
                              <p>{projectData.projectCountryName}</p>
                            </div>
                          </div>
                          {/* <GridItem xs={4}>
                            <Flag
                              imageName={
                                getCountryData(
                                  projectData.projectCountryName,
                                  projectData.projectCountryID || 0
                                ) + '.png'
                              }
                            />
                          </GridItem>
                          <GridItem xs={8}>
                            <p>{projectData.projectCountryName}</p>
                            <p>Papouasie Nouvelle Guinée</p>
                          </GridItem> */}
                        </GridContainer>
                      }
                    ></ProjectStats>
                  </GridItem>
                  <GridItem md={6} sm={6} xs={12}>
                    <ProjectStats
                      title={t('project_location')}
                      body={projectLocation}
                    ></ProjectStats>
                  </GridItem>
                  <GridItem md={6} sm={6} xs={12}>
                    <ProjectStats
                      title={t('projected_employment')}
                      body={
                        projectData.projectProjectedEmployee &&
                        projectData.projectProjectedEmployee > 0
                          ? projectData.projectProjectedEmployee.toLocaleString()
                          : 'N/A'
                      }
                    ></ProjectStats>
                  </GridItem>
                  <GridItem md={6} sm={6} xs={12}>
                    <ProjectStats
                      title={t('nda')}
                      body={
                        projectData.isNonDisclosureAgreement ? (
                          <>{t('Yes')}</>
                        ) : (
                          <>{t('No')}</>
                        )
                      }
                    ></ProjectStats>
                  </GridItem>
                  <GridItem md={6} sm={6} xs={12}>
                    <ProjectStats
                      title={t('total_investment')}
                      body={
                        <>
                          {numFormatter(projectData.totalInvestmentCost)} EUR
                          {projectData.capitalRaised > 0 &&
                          projectData.capitalRaised <=
                            projectData.totalInvestmentCost
                            ? ` (${numFormatter(
                                projectData.totalInvestmentCost -
                                  projectData.capitalRaised
                              )} EUR)`
                            : ''}
                        </>
                      }
                    ></ProjectStats>
                  </GridItem>
                  <GridItem md={6} sm={6} xs={12}>
                    <ProjectStats
                      title={`${t('related_sdg')}`}
                      customClassName="texst-v1"
                      body={
                        <ul className="small-sdg-list">
                          {projectData &&
                            (projectData.sdgid || []).map(function(id, index) {
                              return (
                                <SDGTooltip
                                  key={index + 1}
                                  sdg={{
                                    sdgid: id,
                                    sdgTitle: projectData?.sdgTitle[index],
                                    sdgDescription:
                                      projectData?.sdgDescription[index],
                                  }}
                                >
                                  <li>
                                    <Image imageName={`SDG${id}.png`} />
                                  </li>
                                </SDGTooltip>
                              );
                            })}
                        </ul>
                      }
                    ></ProjectStats>
                  </GridItem>
                  {projectData.projectEFSDType &&
                  projectData.projectEFSDType.length > 0 ? (
                    <GridItem md={6} sm={6} xs={12}>
                      <div className="project-efsd">
                        <ProjectStats
                          title={t('alignment_efsd_window')}
                          body={
                            <ul>
                              {projectData &&
                                (projectData.projectEFSDType || []).map(
                                  (name, index) => {
                                    return (
                                      <Tooltip
                                        title={name}
                                        key={index}
                                        placement="top-start"
                                      >
                                        <li>{name}</li>
                                      </Tooltip>
                                    );
                                  }
                                )}
                            </ul>
                          }
                        ></ProjectStats>
                      </div>
                    </GridItem>
                  ) : (
                    ''
                  )}
                </GridContainer>

                {/* <div className="potential-eligibility">
                  <h5>
                    {`${t(
                      'Potential Eligibility for EU EIP Themes and EFSD Guarantees'
                    )} :`}
                  </h5>
                  <div className="theme-section">
                    <div className="theme-header">
                      <Tooltip
                        title="Digital development for all"
                        placement="top-start"
                      >
                        <img
                          src={require('../assets/img/thematicAreas/Area4_new.png')}
                          alt=""
                        ></img>
                      </Tooltip>
                      <div>
                        <p>A. Digitalisation</p>
                      </div>
                    </div>
                    <div className="theme-body">
                      <ul>
                        <li>
                          <div>1. FMO Ventures Programme</div>
                          <div>
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              onClick={() => {
                                window.open(
                                  'https://ec.europa.eu/eu-external-investment-plan/projects/fmo-ventures-programme_en',
                                  '_blank'
                                );
                              }}
                            >
                              Access
                            </Button>
                          </div>
                        </li>
                        <li>
                          <div>
                            2. European Health Guarantee Platform for Africa
                          </div>
                          <div>
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              onClick={() => {
                                window.open(
                                  'https://ec.europa.eu/eu-external-investment-plan/content/european-health-guarantee-platform-africa_en',
                                  '_blank'
                                );
                              }}
                            >
                              Access
                            </Button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="theme-section">
                    <div className="theme-header">
                      <Tooltip
                        title="Digital development for all"
                        placement="top-start"
                      >
                        <img
                          src={require('../assets/img/thematicAreas/Area3_new.png')}
                          alt=""
                        ></img>
                      </Tooltip>
                      <div>
                        <p>B. Sustainable energy and connectivity</p>
                      </div>
                    </div>
                    <div className="theme-body">
                      <ul>
                        <li>
                          <div>1. Africa GreenCo</div>
                          <div>
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              onClick={() => {
                                window.open(
                                  'https://ec.europa.eu/eu-external-investment-plan/content/africa-greenco_en',
                                  '_blank'
                                );
                              }}
                            >
                              Access
                            </Button>
                          </div>
                        </li>
                        <li>
                          <div>2. Boosting Investment in Renewable Energy</div>
                          <div>
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              onClick={() => {
                                window.open(
                                  'https://ec.europa.eu/eu-external-investment-plan/projects/renewable-energy-support-programme-mainly-rural-areas-sub-saharan-africa_en',
                                  '_blank'
                                );
                              }}
                            >
                              Access
                            </Button>
                          </div>
                        </li>
                        <li>
                          <div>
                            3. Renewable Energy Support Programme for Mainly
                            Rural Areas in Sub-Saharan Africa
                          </div>
                          <div>
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              onClick={() => {
                                window.open(
                                  'https://ec.europa.eu/eu-external-investment-plan/projects/renewable-energy-support-programme-mainly-rural-areas-sub-saharan-africa_en',
                                  '_blank'
                                );
                              }}
                            >
                              Access
                            </Button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="theme-section">
                    <div className="theme-header">
                      <Tooltip
                        title="Digital development for all"
                        placement="top-start"
                      >
                        <img
                          src={require('../assets/img/thematicAreas/Area1_new.png')}
                          alt=""
                        ></img>
                      </Tooltip>
                      <div>
                        <p>C. Small businesses and agriculture</p>
                      </div>
                    </div>
                    <div className="theme-body">
                      <ul>
                        <li>
                          <div>1. NASIRA Risk-Sharing Facility</div>
                          <div>
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              onClick={() => {
                                window.open(
                                  'https://ec.europa.eu/eu-external-investment-plan/projects/nasira-risk-sharing-facility_en',
                                  '_blank'
                                );
                              }}
                            >
                              Access
                            </Button>
                          </div>
                        </li>
                        <li>
                          <div>
                            2. Archipelagos - One Platform for Africa (ONE4A)
                          </div>
                          <div>
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              onClick={() => {
                                window.open(
                                  'https://ec.europa.eu/eu-external-investment-plan/projects/archipelagos-one-platform-africa-one4a_en',
                                  '_blank'
                                );
                              }}
                            >
                              Access
                            </Button>
                          </div>
                        </li>
                        <li>
                          <div>
                            3. FISEA+ EFSD SME and Agribusiness lnvestment
                            Guarantee
                          </div>
                          <div>
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              onClick={() => {
                                window.open(
                                  'https://ec.europa.eu/eu-external-investment-plan/projects/fisea-smes-and-agribusiness-investment-guarantee_en',
                                  '_blank'
                                );
                              }}
                            >
                              Access
                            </Button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="theme-action">
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={() => {
                        window.open(
                          'https://ec.europa.eu/eu-external-investment-plan/eu-eip-guarantees_en',
                          '_blank'
                        );
                      }}
                    >
                      View All
                    </Button>
                  </div>
                  <div className="theme-resources">
                    <div className="resources-container">
                      <h5>{`${t('Available resources')}`}</h5>
                      <ul>
                        <li>
                          <div className="theme-preview">
                            <a
                              href="https://drive.google.com/file/d/13ZxQg56hDT-TE5GagugTRxf_UAuxwHL8/view?usp=sharing"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              <Image imageName={'eu_inv_plan.JPG'} />
                              <div className="theme-text">
                                EU External Investment Plan
                              </div>
                              <span className="overlay">
                                <GetApp />{' '}
                              </span>
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className="theme-preview">
                            <a
                              href="https://drive.google.com/file/d/1KrKBkJylEIcIncYykf5j3Xe3LaBOGlzG/view?usp=sharing"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              <Image imageName={'eu_qa_inv_plan.JPG'} />
                              <div className="theme-text">
                                Question and Answers about EU External
                                Investment Plan
                              </div>
                              <span className="overlay">
                                <GetApp />{' '}
                              </span>
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className="theme-preview last">
                            <a
                              href="https://ec.europa.eu/eu-external-investment-plan/eu-eip-guarantees_en"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              <div className="theme-text">
                                All Themes and Guarantee
                              </div>
                              <span className="overlay">
                                <DoubleArrowIcon />{' '}
                              </span>
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
                {/* <GridItem md={6} sm={6} xs={12}> */}
                <div className="project-stages">
                  <ProjectStats
                    title={t('stages_project_development_studies')}
                    body={
                      <>
                        {projectData &&
                          (projectData.projectDevelopmentStage || []).map(
                            (name, index) => {
                              return (
                                <DevStage
                                  key={index}
                                  name={name}
                                  index={index}
                                  length={
                                    projectData.projectDevelopmentStage.length
                                  }
                                ></DevStage>
                              );
                            }
                          )}
                      </>
                    }
                  ></ProjectStats>
                </div>
                {/* </GridItem> */}
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <div className="institution-name">
                  <Card className="card">
                    <CardContent className="card-content">
                      {projectData.institutionName &&
                      projectData.institutionName.length > 0 ? (
                        <div>
                          <p className="title">{t('prepared_by')}</p>
                          <div className="fx-column fx-justfy-start">
                            <span className="projectLogoImage">
                              {projectData.institutionLogo &&
                              projectData.institutionLogo.length > 0 ? (
                                <img
                                  src={projectData.institutionLogo}
                                  alt=""
                                ></img>
                              ) : (
                                ''
                              )}
                            </span>
                          </div>
                          <p>
                            {projectData.institutionName
                              ? projectData.institutionName
                              : ''}
                          </p>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className="project-contact-form">
                        <ExpansionPanel
                          expanded={openChatForm}
                          onChange={viewContactClick}
                          className={`${styleClasses.panel} contact-panel`}
                        >
                          <ExpansionPanelSummary
                            className={styleClasses.summary}
                            expandIcon={
                              openChatForm ? (
                                <ExpandLessIcon
                                  className={styleClasses.expand}
                                />
                              ) : (
                                <ExpandMoreIcon
                                  className={styleClasses.expand}
                                />
                              )
                            }
                          >
                            <div
                              className={`${styleClasses.heading} ${
                                openChatForm ? styleClasses.headingExpanded : ''
                              } `}
                            >
                              {t('contact')}
                            </div>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails
                            className={styleClasses.formContents}
                          >
                            <ChatPopup
                              contactUserId={projectData.iosContactUserID}
                              projectId={projectData.projectID}
                              closeForm={() => {
                                setOpenChatForm(false), setOpenSnack(true);
                              }}
                            />
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      </div>
                    </CardContent>

                    {/* <CardActions className="card-action">
                        <IconButton>
                          <ArrowRightAltIcon />
                        </IconButton>
                      </CardActions> */}
                  </Card>
                </div>
                {projectData ? (
                  <div>
                    {projectData && projectData.isCompanyPublished ? (
                      <div className="org-details">
                        <h3>
                          {projectData.sourceEntityTypeName &&
                          projectData.sourceEntityTypeName.length > 0
                            ? t(projectData.sourceEntityTypeName)
                            : t('Organization')}
                        </h3>
                        <div className="org-summary">
                          <ul>
                            <li>
                              <div className="summary-item-logo">
                                <AccountBalanceIcon />
                              </div>
                              <div className="summary-item">
                                <p className="summary-item-title">
                                  {t('Name')}
                                </p>
                                <p>
                                  {projectData.sourceEntityName
                                    ? projectData.sourceEntityName
                                    : 'N/A'}
                                </p>
                              </div>
                            </li>
                            {projectData.sourceEntityTypeName &&
                            projectData.sourceEntityTypeName.length > 0 &&
                            projectData.sourceEntityTypeName === 'Company' ? (
                              <li>
                                <div className="summary-item-logo">
                                  <CalendarTodayIcon />
                                </div>
                                <div className="summary-item">
                                  <p className="summary-item-title">
                                    {t('Year of establishment')}
                                  </p>
                                  <p>
                                    {projectData.yearOfEstablishment
                                      ? projectData.yearOfEstablishment
                                      : 'N/A'}
                                  </p>
                                </div>
                              </li>
                            ) : null}
                            {projectData &&
                              projectData.sourceEntityTotalSale &&
                              projectData.sourceEntityTotalSale.sort(
                                (a, b) => b.annualSalesYear - a.annualSalesYear
                              )[0].salesAmount > 0 && (
                                <li>
                                  <div className="summary-item-logo">
                                    <MoneyIcon />
                                  </div>
                                  <div className="summary-item">
                                    <p className="summary-item-title">
                                      {t('Sales')}
                                    </p>
                                    <p>
                                      {getCompanySales(
                                        projectData.sourceEntityTotalSale
                                      )}
                                    </p>
                                  </div>
                                </li>
                              )}
                            <li>
                              <div className="summary-item-logo">
                                <ZoomOutMapIcon />
                              </div>
                              <div className="summary-item">
                                <p className="summary-item-title">
                                  {t('Size')}
                                </p>
                                <p>
                                  {projectData.sourceEntityTotalSize &&
                                  projectData.sourceEntityTotalSize > 0
                                    ? `${(
                                        projectData.sourceEntityTotalSize || ''
                                      ).toLocaleString()} (incl.
                        ${
                          projectData.partTimeShare > 0
                            ? (
                                Math.round(
                                  (projectData.partTimeShare *
                                    projectData.sourceEntityTotalSize) /
                                    100
                                ) || ''
                              ).toLocaleString()
                            : 0
                        } part-time)`
                                    : 'N/A'}
                                </p>
                              </div>
                            </li>
                            <li>
                              <div className="summary-item-logo">
                                <LocalOfferIcon />
                              </div>
                              <div className="summary-item">
                                <p className="summary-item-title">
                                  {t('organization_sector')}
                                </p>
                                <p>
                                  {projectData.sourceEntitySector &&
                                  projectData.sourceEntitySector.length > 0
                                    ? projectData.sourceEntitySector[0]
                                    : 'N/A'}
                                </p>
                              </div>
                            </li>
                            {/* Not in new design, ask where to accomodate these details or whether to include those details or not */}
                            {/* <li>
                              <div>
                                {projectData.isISO9000 ? (
                                  <p>ISO9000 {t('Certified')}</p>
                                ) : null}
                              </div>
                            </li>
                            <li>
                              <div>
                                {projectData.isHACCP ? (
                                  <p>HACCP {t('Certified')}</p>
                                ) : null}
                              </div>
                            </li> */}
                            <li>
                              <div className="summary-item-logo">
                                <RoomIcon />
                              </div>
                              <div className="summary-item">
                                <p className="summary-item-title">
                                  {t('Physical address')}
                                </p>
                                <p>
                                  {projectData
                                    ? `${getAppendedCommaValue(
                                        projectData.sourceEntityAddress
                                      )}
                                 ${getAppendedCommaValue(
                                   projectData.sourceEntityCityName
                                 )}
                                 ${
                                   projectData.sourceEntityRegionName
                                     ? projectData.sourceEntityRegionName + ','
                                     : ''
                                 } ${
                                        projectData.sourceEntityCountryName
                                          ? projectData.sourceEntityCountryName
                                          : ''
                                      }`
                                    : ''}
                                </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </GridItem>
            </GridContainer>
          ) : (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div className="page-loader-wrapper">
                  {loading ? (
                    <div width="100%">
                      <div className="three-dots-loader">
                        <ThreeDots
                          color="#5990ff"
                          height={100}
                          width={100}
                          timeout={10000}
                        />
                      </div>
                    </div>
                  ) : (
                    t(nodataMessage)
                  )}
                </div>
              </GridItem>
            </GridContainer>
          )}
          <Snackbar
            openSnack={openSnack}
            openSnackFav={openSnackFav}
            setOpenSnack={setOpenSnack}
            setOpenSnackFav={setOpenSnackFav}
            message={
              (openSnack && t('details_shared_ty')) ||
              (openSnackFav && t('removed_favorites'))
            }
            icon={
              (openSnack && '') || (openSnackFav && <StarBorderOutlinedIcon />)
            }
          />
        </div>
      </Layout>
    </Page>
  );
};

ProjectDetails.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.any,
};

export default ProjectDetails;

const DevStage = ({ ...props }) => {
  const { name, length } = props;
  const { t } = useTranslation();
  let stageName;
  if (length == 1 && name === t('preliminary')) {
    stageName = (
      <ul>
        <li>
          <div>{t('studies_initiated')}</div>
          <CheckCircle className="active" />
        </li>
      </ul>
    );
  } else if (length == 2 && name === t('initial_studies_completed')) {
    stageName = (
      <ul>
        <li>
          <div>{t('studies_initiated')}</div>
          <CheckCircle className="active" />
        </li>
        <li>
          <div>{t('studies_completed')}</div>
          <CheckCircle className="active" />
        </li>
        <li>
          <div>{t('other_studies')}</div>
          <CheckCircle className="inactive" />
        </li>
      </ul>
    );
  } else if (length == 3 && name === t('feasibility_study_completed')) {
    stageName = (
      <ul>
        <li>
          <div>{t('studies_initiated')}</div>
          <CheckCircle className="active" />
        </li>
        <li>
          <div>{t('studies_completed')}</div>
          <CheckCircle className="active" />
        </li>
        <li>
          <div>{t('other_studies')}</div>
          <CheckCircle className="active" />
        </li>
        <li>
          <div>{t('feasibility_study')}</div>
          <CheckCircle className="inactive" />
        </li>
      </ul>
    );
  } else if (length == 4 && name === t('Approval_required_obtained')) {
    stageName = (
      <ul>
        <li>
          <div>{t('studies_initiated')}</div>
          <CheckCircle className="active" />
        </li>
        <li>
          <div>{t('studies_completed')}</div>
          <CheckCircle className="active" />
        </li>
        <li>
          <div>{t('other_studies')}</div>
          <CheckCircle className="active" />
        </li>
        <li>
          <div>{t('feasibility_study')}</div>
          <CheckCircle className="active" />
        </li>
      </ul>
    );
  }

  return <>{stageName}</>;
};

DevStage.propTypes = {
  name: PropTypes.any,
  index: PropTypes.any,
  length: PropTypes.any,
};
